import * as React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import LayoutTwo from '../components/layoutTwo';
import '../styles/contact/layout.scss';
import { motion } from 'framer-motion';

const containerVariants = {
    hidden: {
        opacity: 0
    },
    visible: {
        opacity: 1,
        transition: {
            delay: 0.3,
            duration: 0.1,
            type: 'tween',
            when: 'beforeChildren',
            staggerChildren: 0.4
        }
    }
};

const contactDetailsVariants = {
    hidden: {
        opacity: 0,
        y: '10px'
    },
    visible: {
        opacity: 1,
        y: 0,
        transition: {
            duration: 1,
            type: 'tween'
        }
    }
};

const HeaderVariants = {
    hidden: {
        opacity: 0,
        x: '50px'
    },
    visible: {
        opacity: 1,
        x: 0,
        transition: {
            duration: 1,
            type: 'tween'
        }
    }
};

const TextVariants = {
    hidden: {
        opacity: 0,
        x: '-50px'
    },
    visible: {
        opacity: 1,
        x: 0,
        transition: {
            duration: 1,
            type: 'tween'
        }
    }
};

const ContactPage = () => {
    const data = useStaticQuery(graphql`
        query MyQuery {
            allWpPage(filter: { slug: { eq: "contact" } }) {
                nodes {
                    title
                    contact {
                        address
                        email
                        pageText
                        phone
                        instagramName
                        instagramLink
                    }
                }
            }
        }
    `);
    const pageTitle = data.allWpPage.nodes[0].title;
    const contactDetails = data.allWpPage.nodes[0].contact;

    return (
        <LayoutTwo>
            <motion.main className="contact" variants={containerVariants} initial="hidden" animate="visible">
                <title>Contact</title>
                <section className="contact__left">
                    <motion.h2 className="page-headers font__large" variants={HeaderVariants}>
                        {pageTitle}
                    </motion.h2>
                    <motion.p className="contact__intro-text page-intros font__large" variants={TextVariants}>
                        {contactDetails.pageText}
                    </motion.p>
                </section>
                <section className="contact__right">
                    <motion.div className="contact__info" variants={contactDetailsVariants}>
                        <a href={'mailto:' + contactDetails.email} target="_blank" rel="noreferrer">
                            <p className="font__large">Email</p>
                            <div className="contact__line-split"></div>
                            <p className="font__mid">{contactDetails.email}</p>
                        </a>
                    </motion.div>

                    <motion.div className="contact__info" variants={contactDetailsVariants}>
                        <a href={contactDetails.instagramLink} target="_blank" rel="noreferrer">
                            <p className="font__large">Instagram</p>
                            <div className="contact__line-split"></div>
                            <p className="font__mid">{contactDetails.instagramName}</p>
                        </a>
                    </motion.div>

                    <motion.div className="contact__info" variants={contactDetailsVariants}>
                        <a href={'tel:' + contactDetails.phone} target="_blank" rel="noreferrer">
                            <p className="font__large">Phone</p>
                            <div className="contact__line-split"></div>
                            <p className="font__mid">{contactDetails.phone}</p>
                        </a>
                    </motion.div>

                    <motion.div className="contact__info" variants={contactDetailsVariants}>
                        <a href="https://goo.gl/maps/NXDUtGb8UuPUUmB79" target="_blank" rel="noreferrer">
                            <p className="font__large">Address</p>
                            <div className="contact__line-split"></div>
                            <p className="font__mid" dangerouslySetInnerHTML={{ __html: contactDetails.address }} />
                        </a>
                    </motion.div>
                </section>
            </motion.main>
        </LayoutTwo>
    );
};

export default ContactPage;

import React from 'react';
import Header from './main-header';
import { Helmet } from 'react-helmet';
import '../styles/base.scss';
import '../styles/typography.scss';
import { motion } from 'framer-motion';

const LayoutTwo = ({ children }) => {
    return (
        <div className="layout">
            <Helmet>
                <meta name="title" content="Art Source" data-react-helmet="true" />
                <title>Art Source</title>
                <meta name="description" content="art source. Innovative installation of contemporary art" />
                <link rel="stylesheet" href="https://use.typekit.net/mtv5rui.css" />
                <link rel="preconnect" href="https://fonts.googleapis.com" />
                <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
                <link
                    href="https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap"
                    rel="stylesheet"
                />
            </Helmet>

            <Header />
            <motion.div
                className="site-wrap"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{
                    type: 'spring',
                    mass: 0.35,
                    stiffness: 75,
                    duration: 0.3
                }}
            >
                {children}
            </motion.div>
        </div>
    );
};

export default LayoutTwo;

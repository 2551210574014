import React, { useEffect } from 'react';
import Navbar from './navbar';
import Logo from '../images/Art_Source_Black_logo.svg';
import MobileNav from './mobileNav';
import { Link } from 'gatsby';
import { useState } from 'react';
import './../styles/menuButton.scss';
const bodyScrollLock = require('body-scroll-lock');
const disableBodyScroll = bodyScrollLock.disableBodyScroll;
const enableBodyScroll = bodyScrollLock.enableBodyScroll;

const Header = () => {
    useEffect(() => {
        bodyScrollLock.clearAllBodyScrollLocks();
    }, []);

    const [menu, setMenu] = useState(false);
    const [menuClass, setMenuClass] = useState('not-open');

    const handleClick = () => {
        const body = document.body;

        if (menu === false) {
            setMenu(true);
            setMenuClass('open');
            disableBodyScroll(body);
        } else {
            setMenu(false);
            setMenuClass('not-open');
            enableBodyScroll(body);
        }
    };

    return (
        <header className="black-header">
            <div className="site-wrap">
                <div className="main-logo__container">
                    <Link to="/" className="main-logo">
                        <img src={Logo} alt="logo" />
                    </Link>
                </div>
                <div className="desktopNav">
                    <Navbar />
                </div>

                {menu && <MobileNav />}

                <div
                    className={'menuButton ' + menuClass}
                    onClick={handleClick}
                    onKeyDown={handleClick}
                    role="button"
                    tabIndex={0}
                >
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
            </div>
        </header>
    );
};

export default Header;
